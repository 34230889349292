import axios from 'axios'
import { apiUrl, employeeApiUrl } from '../../constants/config'

export default {
  state: {
    currentUser: localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')) : null,
    loginError: null,
    processing: false,
    userUpdated: false,
    riderAdded: false,
    accountAdded: false,
    payoutAdded: false,
    daasEvent: false,
  },
  getters: {
    currentUser: state => state.currentUser,
    processing: state => state.processing,
    loginError: state => state.loginError,
    userUpdated: state => state.userUpdated,
    riderAdded: state => state.riderAdded,
    accountAdded: state => state.accountAdded,
    payoutAdded: state => state.payoutAdded,
    daasEvent: state => state.daasEvent,
  },
  mutations: {
    setUser(state, payload) {
      state.currentUser = payload
      state.processing = false
      state.loginError = null
      state.userUpdated = false
    },
    setLogout(state) {
      state.currentUser = null
      state.processing = false
      state.loginError = null
    },
    setProcessing(state, payload) {
      state.processing = payload
      state.loginError = null
    },
    setError(state, payload) {
      state.loginError = payload
      state.currentUser = null
      state.processing = false
    },
    clearError(state) {
      state.loginError = null
    },
  },
  actions: {

    login({ commit }, payload) {
      commit('clearError')
      commit('setProcessing', true)

      const url = payload.isEmployee ? `${employeeApiUrl}login` : `${apiUrl}login`
      const userType = payload.isEmployee ? 'employee' : 'partner'

      axios({
        url,
        data: { email: payload.email, password: payload.password },
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
      })
        .then(response => {
          const item = {
            token: response.data.data.token,
            user: response.data.data.user,
            userType,
          }

          localStorage.setItem('user', JSON.stringify(item))
          commit('setUser', { token: item.token, user: item.user, userType })
        })
        .catch(error => {
          localStorage.removeItem('user')
          commit('setError', error)
        })
    },

    logout({ commit }, payload) {
      const { userType } = JSON.parse(localStorage.getItem('user'))

      const url = userType === 'employee'
        ? `${employeeApiUrl}logout` : `${apiUrl}logout`

      console.log('url', url)
      console.log('userType', userType)

      axios({
        url,
        data: {},
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${payload.token}`,
        },
      })
        .then(() => {
          localStorage.removeItem('user')
          commit('setLogout')
        })
        .catch(() => {
          localStorage.removeItem('user')
          commit('setLogout')
        })
    },

    profile({ commit }, payload) {
      const { userType } = JSON.parse(localStorage.getItem('user'))

      const url = userType === 'employee'
        ? `${employeeApiUrl.slice(0, -1)}` : `${apiUrl.slice(0, -1)}`

      try {
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${payload.token}`,
            },
          })
          .then(response => {
            const item = {
              token: payload.token,
              user: response.data.data,
              userType,
            }

            localStorage.setItem('user', JSON.stringify(item))
            commit('setUser', { token: item.token, user: item.user, userType })
          })
          .catch(error => {
            console.log(error)
          })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
