// export const baseUrl = 'https://boxstop.local'
// export const baseUrl = 'https://auth.boxstopapp.com'

// export const baseUrl = 'http://127.0.0.1:8000'
export const baseUrl = 'https://test.boxstopapp.com'
export const apiUrl = `${baseUrl}/api/partner/`
export const employeeApiUrl = `${baseUrl}/api/employee/`
export const capiUrl = `${baseUrl}/api/customer/`

export const currentUser = {
  token: '',
  user: {},
}
