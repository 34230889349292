export default (to, from, next) => {
  const user = localStorage.getItem('user')

  if (user != null && user.length > 0) {
    // verify with firebase or jwt
    next()
  } else {
    localStorage.removeItem('user')
    next('/login')
  }
}
