import Vue from 'vue'
import VueRouter from 'vue-router'

import { apiUrl, employeeApiUrl } from '@/constants/config'
import store from '@/store'
import axios from 'axios'
import AuthRequired from './authRequired'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      beforeEnter: AuthRequired,
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dispatches',
      name: 'dispatches',
      component: () => import('@/views/Dispatches.vue'),
      beforeEnter: AuthRequired,
      meta: {
        pageTitle: 'Dispatches',
        breadcrumb: [
          {
            text: 'Dispatches',
            active: true,
          },
        ],
      },
    },
    {
      path: '/daas',
      name: 'daas',
      component: () => import('@/views/Daas.vue'),
      beforeEnter: AuthRequired,
      meta: {
        pageTitle: 'DAAS',
        breadcrumb: [
          {
            text: 'DAAS',
            active: true,
          },
        ],
      },
    },
    {
      path: '/riders',
      name: 'riders',
      component: () => import('@/views/Riders.vue'),
      beforeEnter: AuthRequired,
      meta: {
        pageTitle: 'Riders',
        breadcrumb: [
          {
            text: 'Riders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/employees',
      name: 'employees',
      component: () => import('@/views/Employees.vue'),
      beforeEnter: AuthRequired,
      meta: {
        pageTitle: 'Employees',
        breadcrumb: [
          {
            text: 'Employees',
            active: true,
          },
        ],
      },
    },
    {
      path: '/payouts',
      name: 'payouts',
      component: () => import('@/views/Payouts.vue'),
      beforeEnter: AuthRequired,
      meta: {
        pageTitle: 'Payouts',
        breadcrumb: [
          {
            text: 'Payouts',
            active: true,
          },
        ],
      },
    },
    {
      path: '/accounts',
      name: 'accounts',
      component: () => import('@/views/Accounts.vue'),
      beforeEnter: AuthRequired,
      meta: {
        pageTitle: 'Accounts',
        breadcrumb: [
          {
            text: 'Accounts',
            active: true,
          },
        ],
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/Settings.vue'),
      beforeEnter: AuthRequired,
      meta: {
        pageTitle: 'Settings',
        breadcrumb: [
          {
            text: 'Settings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/verify-account',
      name: 'verify-account',
      component: () => import('@/views/VerifyAccount.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/create-daas',
      name: 'create-daas',
      beforeEnter: AuthRequired,
      component: () => import('@/views/CreateDaas.vue'),
      meta: {
        pageTitle: 'Create Daas',
        breadcrumb: [
          {
            text: 'Create Daas',
            active: false,
          },
        ],
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }

  // check if user token is ACTIVE and LOGOUT if not
  axios
    .get(`${apiUrl}wallet`, {
      headers: {
        Authorization: `Bearer ${store.state.user.currentUser?.token}`,
      },
    })
    .catch(error => {
      const { userType } = JSON.parse(localStorage.getItem('user'))

      const url = userType === 'employee'
        ? `${employeeApiUrl}logout` : `${apiUrl}logout`

      if (error.response.status === 401) {
        axios({
          url,
          data: {},
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${store.state.user.currentUser?.token}`,
          },
        }).then(() => {
          localStorage.removeItem('user')
          store.state.user.currentUser = null
        }).catch(() => {
          localStorage.removeItem('user')
          store.state.user.currentUser = null
        })
      }
    })
})

export default router
